/* Global Values */
/* Start Custom CSS*/
nav.navigation-menu.w-nav-menu {
    z-index: 2000;
}

.title-wrapper h1 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Lobster Two', sans-serif;
    font-size: 50px;
    line-height: 49px;
    font-style: italic;
    font-weight: 700;
    text-transform: none;
}

footer.bottomfooter a:not(.mbss-btn-icon) {
    display: inline-block;
}

footer a {
    display: block;
}

    footer a:hover {
        color: white;
    }

a.w-inline-block {
    display: inline-block;
}

table {
    border-bottom: 1px solid #ccc;
    width: 100%;
}

td {
    padding: 5px 10px;
}

td,
th {
    border: 1px solid #e7e7e7;
}

section:nth-of-type(2n) {
    background-color: #e7e7e7;
}

.footer p {
    color: rgba(255, 255, 255, 0.61);
}

.topfooter a {
    display: block;
}

    .topfooter a:hover {
        color: white;
        text-decoration: none;
    }

.w-section > .w-section {
    padding: 0px;
}

.service-column p {
    padding: 0px 20px;
    margin-top: -10px;
}

.topfooter p {
    color: rgba(255, 255, 255, 0.61);
}

/*.button-wrapper a:not(.mbss-btn-icon):not(.re-icon) {
    display: inline-block;
    padding: 16px 25px;
    border: 2px solid transparent;
    border-radius: 30px;
    background-color: #db4d01;
    -webkit-transition: color 500ms ease, border 500ms ease, background-color 500ms ease;
    transition: color 500ms ease, border 500ms ease, background-color 500ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    margin-top: 10px;
    margin-bottom: 20px;
}

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):hover {
        border-color: #db4d01;
        background-color: transparent;
        color: #db4d01;
        text-decoration: none;
    }*/

.col {
    padding-left: 5px;
    padding-right: 5px;
}

#Search::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #555;
}

#Search:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #555;
}

#Search::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #555;
}

#Search:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #555;
}

input#Join-Us::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #555;
}

input#Join-Us:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #555;
}

input#Join-Us::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #555;
}

input#Join-Us:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #555;
}

.w-row > .service-column > p {
    padding: 0px 5px;
}
/* End custom CSS */
/* Start Master-Parts CSS */
.product-detail-price:nth-child(2) {
    margin-top: 10px;
}

.shoppingcontainer .row:nth-child(2),
.visible-xs,
div#product-detail-variant .section:nth-child(2) {
    display: none;
}

div#discount-input {
    float: left;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    margin-right: 3px;
}

.w-row.shopppingrow > div > ul {
    list-style-type: none;
    padding-left: 0;
}

#paymentMethods > div > .w-radio {
    padding-left: 0;
}

.product-code {
    font-size: 17px;
    font-weight: 900;
}

.qtybasket.space {
    margin-top: 20px;
}

.qtybasket {
    margin-bottom: 5px;
    margin-top: 10px;
}

.variant-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
}

.w-col.w-col-6.text-align-right {
    text-align: right;
}

.w-container.product-list-head {
    padding-bottom: 50px;
}

.breadcrumb {
    margin-top: -40px;
    margin-bottom: 20px;
}

.product-detail-bottom-tabs {
    margin-top: 50px;
}

.clear-btn.clear-filters-button,
.green-btn {
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
    padding: 18px;
    border-radius: 0;
    background-color: #2196f3;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.pagination-text,
.product-list-header-text table {
    display: none;
}

.clear-btn.clear-filters-button:hover,
.green-btn:hover {
    background-color: #001526;
    color: #2196f3;
    text-decoration: none;
}

.input,
input#discount-input-tbx {
    height: 46px;
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 300;
}

.clear-btn.clear-filters-button {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.input,
th {
    margin-bottom: 10px;
}

.input {
    padding-top: 18px;
    padding-bottom: 18px;
    border: 2px solid transparent;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
}

    .input:hover {
        border-color: #2196f3;
    }

td,
th {
    border: 1px solid #e7e7e7;
}

input#discount-input-tbx {
    padding: 18px;
    margin-right: 10px;
    -webkit-transition: border .5s ease;
    transition: border .5s ease;
    border: 1px solid #2196E0;
    width: 250px;
}

th {
    font-family: Lato, sans-serif;
    font-size: 1px 10px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 7px;
}

.new,
.table-title {
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
}

td {
    padding: 5px 10px;
}

table {
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
}

.glyphicon {
    padding: 10px;
    text-decoration: none;
    position: relative;
    top: 6px;
    font-size: 17px;
    -webkit-transition: color .5s ease;
}

.new,
.video-btn {
    position: absolute;
}

.table-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 39px;
    font-weight: 900;
}

.spec-table {
    margin-bottom: 40px;
}

.new {
    top: 13px;
    right: 13px;
    width: 50px;
    height: 50px;
    border: 2px #fff;
    border-radius: 100%;
    background-color: #2196f3;
    opacity: 1;
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    color: #fff;
    font-size: 17px;
    line-height: 52px;
    font-weight: 600;
    text-align: center;
}

.product-name {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.product-price {
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
}

.price-old {
    text-decoration: line-through;
}

.productwrapper,
.tab,
.tab:hover {
    text-decoration: none;
}

.pageno {
    padding: 8px;
    margin-right: 0;
    -webkit-transition: color 0.5s ease, background-color 0.5s ease;
    transition: color 0.5s ease, background-color 0.5s ease;
    color: #444;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    background-color: transparent;
}

    .pageno:hover,
    span.w-button.pageno.w--current {
        color: #2196f3;
    }

select#Country-2,
select#billCountry,
select#delCountry,
select#node,
select#product-sort {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 98% 50%;
    border: 0 solid #ccc;
    display: block;
    height: 45px;
    padding: 1px 10px;
    background-color: #f7f7f7;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
}

.w-form.qtywrapper {
    margin: 0;
}

label {
    display: inline-block;
    margin-right: 10px;
    color: #818181;
    font-weight: 300;
}

select#node,
select#product-sort {
    position: relative;
    margin-top: -13px;
    width: auto;
    margin-bottom: 0;
    float: left;
    background-repeat: no-repeat;
    border-radius: 0;
    margin-right: 10px;
    font-style: normal;
}

.productwrapper,
.productwrapper img {
    margin-bottom: 20px;
}

select#Country-2,
select#billCountry,
select#delCountry {
    width: 100%;
    background-repeat: no-repeat;
    border-radius: 0;
    margin-right: 10px;
    font-style: normal;
}

.qty-tbx,
.select.quantity,
select.w-select {
    background-position: 85% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    font-style: normal;
}

.select.quantity,
select.w-select {
    padding: 1px 10px;
    text-align: center;
    background-color: #f7f7f7;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    border: 0 solid #ccc;
    border-radius: 0;
    display: inline-block;
    height: 56px;
    margin-right: 6px;
    margin-top: 0;
}

select#Quantity-2 {
    margin-top: -1px;
}

.select.quantity {
    width: 53px;
    margin-top: -1px;
}

.w-form.results-filter-wrapper {
    position: relative;
    display: inline-block;
    float: right;
}

.w-row.filter-select-wrapper {
    margin-top: 13px;
    margin-bottom: 20px;
    color: #818181;
    font-weight: 300;
}

.productwrapper {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 20px;
    border: 1px solid #eee;
    background-color: #fff;
    -webkit-transition: color 0.5s ease, box-shadow 0.5s ease, -webkit-transform 0.5s ease;
    transition: color 0.5s ease, box-shadow 0.5s ease, -webkit-transform 0.5s ease;
    transition: color 0.5s ease, box-shadow 0.5s ease, transform 0.5s ease;
    transition: color 0.5s ease, box-shadow 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
    color: #222;
    text-align: center;
}

    .productwrapper:hover {
        box-shadow: 0 7px 11px -1px rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        color: #000;
        text-decoration: none;
    }

    .productwrapper.product-name,
    .productwrapper.product-price {
        padding: 0 10px;
    }

.filter-text {
    display: inline-block;
    float: left;
}

.filter-heading {
    padding: 15px 20px;
    margin-bottom: 0;
}

.filter-heading-icon {
    display: inline-block;
}

.brand-filter {
    display: none;
}

.qty-tbx,
.tab,
.w-form.qtywrapper {
    display: inline-block;
    text-align: center;
}

.qty-tbx {
    padding: 0 10px;
    background-color: #f7f7f7;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BM…8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    border: 0 solid #ccc;
    border-radius: 0;
    height: 48px;
    width: 60px;
    margin: 0 auto;
}

.tab {
    width: auto;
    margin-bottom: 10px;
    padding: 18px 25px;
    border-radius: 0;
    background-color: #666;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin-right: 2px;
}

    .tab:hover {
        background-color: #001526;
        color: #2196f3;
    }

    .tab.w--current {
        background-color: #2196f3;
        color: #fff;
    }

.product-detail-price {
    display: block;
    margin-top: 27px;
    margin-bottom: 10px;
    color: #2196f3;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
}

    .product-detail-price > span.price-old {
        color: #444;
        text-decoration: line-through;
    }

.productOptions {
    width: auto;
    padding: 1px 20px 1px 10px;
    background-color: #f7f7f7;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAyCAMAAABbLho1AAAAV1BMVEX///+GhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoaGhoabKRxlAAAAHHRSTlMAAwYSFRgeLTA8RVRXcnWNkJmcusPM2+Tn6vb8ZDtY+wAAAE1JREFUeAHtwccNxCAABMA9zjkHHAzbf51GgBAST3+ZQSrrR0RqpVsE/5O8S3i/nYYUcGZaC75baW1whKRxCHjFRT4VgkbpDpFhQpZ4AXJXBGjD9piXAAAAAElFTkSuQmCC);
    background-position: 96% 50%;
    background-repeat: no-repeat;
    border: 0 solid #ccc;
    border-radius: 0;
    height: 53px;
    margin-right: 10px;
    font-style: normal;
}

.space {
    margin-top: 40px;
}

.tabmenu {
    margin-bottom: 40px;
    margin-left: 5px;
    border-bottom: 1px solid #eee;
}

.w-row.hide {
    display: block;
}

.product-tab-menu {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    float: left;
    border-right: 1px none #000;
    border-bottom: 1px none #000;
    text-align: left;
}

.product-tab:first-child,
.product-tab:nth-child(4n+0) {
    margin-left: 0;
}

.product-tab:nth-child(3n+0) {
    margin-right: 0;
}

.tabcontent {
    border: none;
}

.product-tab {
    width: 32%;
    margin-right: 1%;
    margin-bottom: 20px;
    margin-left: 1%;
    padding: 0;
    float: left;
    background-color: #ccc;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 1px solid transparent;
}

.cart-table,
.video-btn,
td.price {
    text-align: center;
}

.product-tab:hover {
    border: 1px solid #2196f3;
}

.product-tab-img {
    width: 100%;
}

.video-btn {
    left: 0;
    right: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    margin: 35% auto;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #2196f3;
    font-size: 19px;
    padding-left: 3px;
}

.productcontent {
    cursor: zoom-in;
    margin-top: 3px;
    margin-bottom: 10px;
}

.product-image {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-bottom: 18px;
    margin-left: auto;
}

.checkboxfield img,
.filter-heading.mobile,
.mini-slider,
.product-detail-slider,
.subdropdown-toggle > .w-icon-dropdown-toggle {
    display: none;
}

.w-widget.w-widget-facebook.social.fb {
    top: -6px;
}

.w-widget.w-widget-gplus.social.googleplus {
    top: 3px;
}

div#add-to-bag {
    margin-bottom: 40px;
}

.spec-table tr:nth-child(2n) {
    background-color: #ddd;
}

#product-variants-table td:first-child {
    width: 50%;
}

#product-variants-table td {
    padding: 0 5px;
}

.product-info td {
    color: #828282;
    padding: 0 10px 0 0;
    font-weight: 300;
}

    .product-info td:last-child {
        text-align: center;
        min-width: 91px;
    }

td.price {
    width: 30%;
}

label.paymentLabel {
    float: none;
}

.cart-table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
}

#checkout .row,
.shopppingrow {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e7e7e7;
}

    #checkout.row:first-child {
        border-bottom: none;
    }

#delivery-options-frame h4 {
    margin-top: 0;
}

#checkout .checkboxfield {
    padding-left: 0;
}

#cart-table tr td:nth-child(3),
.cart-table tr td:nth-child(1),
.cart-table tr td:nth-child(2) {
    width: 15%;
}

.cart-table tr td:nth-child(3) {
    width: 25%;
}

.w-tab-pane h2 {
    text-align: left;
}
/* End Master-Parts CSS */
@media screen and (min-width: 1200px) {
    .w-container {
        max-width: 1170px;
    }

    .megalist {
        width: 100%;
        max-width: 1170px;
        min-width: 1170px;
    }

        .megalist.w--open {
            left: -580px;
        }
}

@media (max-width: 991px) {
    .title-wrapper h1 {
        font-size: 44px;
        line-height: 44px;
    }
}

@media (max-width: 767px) {
    .title-wrapper h1 {
        font-size: 40px;
        line-height: 39px;
    }
}

body {
    padding-top: 0;
}

.containerslider .mbss-content a {
    background-color: #db4d01;
    border: 2px solid transparent;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 23px;
    margin-top: 20px;
    padding: 16px 25px;
    text-decoration: none;
    text-shadow: none;
    text-transform: uppercase;
    transition: color 500ms ease 0s, background-color 500ms ease 0s, border 500ms ease 0s;
}

    .containerslider .mbss-content a:hover {
        border-color: #fff;
        background-color: transparent;
        color: #fff;
        text-decoration: none;
    }

.elementwrapper {
    overflow: visible;
}

    .elementwrapper:hover {
        z-index: 9999;
    }

.productimg {
    height: auto;
    border-radius: 12px 12px 0 0;
}

.brandsection {
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

img {
    height: auto;
}
/*.productImageWrapper .elementwrapper
{
    border:none;
    width:auto;
    height:auto;
    margin:0;
    padding:0;
    max-width:50%;
}
.product-img
{
    max-width:100%;
}*/
.productImageWrapper {
    max-width: 50%;
    display: inline-block;
    float: left;
}

    .productImageWrapper img {
        max-width: 100%;
        width: 100%;
    }

.redactor-editor,
.redactor-box,
.redactor-box textarea {
    z-index: 99999 !important;
}
/* Size specific */
/* all devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .the-website {
        padding-top: 93px;
    }
}
/*Fix after update*/
.button-wrapper a:not(.re-button-icon):not(.mbss-btn-icon):not(.re-icon):not(.redactor-toolbar) {
    display: inline-block;
    padding: 16px 25px;
    border: 2px solid transparent;
    border-radius: 30px;
    background-color: #db4d01;
    -webkit-transition: color 500ms ease, border 500ms ease, background-color 500ms ease;
    transition: color 500ms ease, border 500ms ease, background-color 500ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    margin-top: 10px;
    margin-bottom: 20px;
}

    .button-wrapper a:not(.re-button-icon):not(.mbss-btn-icon):not(.re-icon):not(.redactor-toolbar):hover {
        border-color: #db4d01;
        background-color: transparent;
        color: #db4d01;
        text-decoration: none;
    }
/*.products {
    display:flex;
    flex-wrap:wrap;
}*/
.banner-text-medium {
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
}
@media (max-width:1024px) {
    .brandsection {
        background-attachment: scroll !important;
    }
}
