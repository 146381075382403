body {
  height: 100%;
  padding-top: 93px;
  font-family: Exo, sans-serif;
  color: #2c1c13;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

h1 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Lobster Two', sans-serif;
  font-size: 80px;
  line-height: 80px;
  font-style: italic;
  font-weight: 700;
  text-transform: none;
}

h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Lobster Two', sans-serif;
  font-size: 50px;
  line-height: 49px;
  font-style: italic;
  font-weight: 700;
  text-transform: none;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Lobster Two', sans-serif;
  font-size: 33px;
  line-height: 42px;
  font-style: italic;
  font-weight: 700;
  text-transform: none;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-top: 0px;
  margin-bottom: 7px;
}

a {
  -webkit-transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease;
  color: #db4d01;
  text-decoration: none;
}

a:hover {
  color: #222;
  text-decoration: underline;
}

ul {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 20px;
  font-style: italic;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 300;
}

.button {
  display: inline-block;
  width: auto;
  margin-bottom: 10px;
  padding: 18px 25px;
  border-radius: 0px;
  background-color: #2196f3;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #001526;
  color: #2196f3;
  text-decoration: none;
}

.button._3 {
  border-color: #fff;
  color: #fff;
}

.button._3:hover {
  background-color: hsla(0, 0%, 100%, .09);
}

.button.button-margin {
  margin-left: 12px;
}

.button.inline {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 16px 27px 14px 23px;
  border: 2px solid transparent;
  border-radius: 0px 30px 30px 0px;
  background-color: #db4d01;
  color: #fff;
  letter-spacing: 1px;
}

.button.inline:hover {
  border-color: #db4d01;
  background-color: transparent;
  color: #db4d01;
}

.button.inline.fontello {
  padding: 11px 19px;
  border-color: transparent;
}

.button.inline.fontello:hover {
  background-color: #4a3021;
  color: #db4d01;
}

.button.inline.fontello.large {
  position: absolute;
  border-radius: 0px;
  font-size: 16px;
}

.button.inline.fontello.large:hover {
  background-color: #fff;
  color: rgba(0, 0, 0, .61);
}

.button.inline.fontello.cart:hover {
  background-color: #d3c8b2;
}

.button.inline.cart:hover {
  background-color: #8a3557;
  color: #fff;
}

.button.clear {
  display: block;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 25px;
  float: left;
}

.button.fontello {
  padding-top: 10px;
  padding-bottom: 10px;
}

.button.fontello:hover {
  background-color: #8a3557;
  color: #fff;
}

.navigation {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  background-color: #4d301d;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-attachment: scroll;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .4);
  text-align: right;
}

.navigation-menu {
  display: block;
  font-weight: 100;
  text-align: center;
}

.navlink {
  position: static;
  display: inline-block;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 17px;
  border-bottom: 1px solid transparent;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: hsla(0, 0%, 100%, .7);
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.navlink:hover {
  color: #db4d01;
  text-decoration: none;
}

.navlink.w--current {
  border-bottom-style: none;
  color: #fff;
}

.navlink.top {
  display: inline-block;
  margin-left: 0px;
  padding: 22px 18px;
  font-size: 14px;
  text-transform: none;
}

.navlink.top:hover {
  background-color: transparent;
  color: #db4d01;
}

.navlink.top.w--current {
  color: #2196f3;
  text-decoration: none;
}

.navlink.top.cart {
  margin-left: 10px;
  float: right;
  background-image: url('../images/pram-icon.png');
  background-position: 0px 50%;
  background-size: 17px;
  background-repeat: no-repeat;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  text-decoration: none;
}

.navlink.top.cart:hover {
  background-image: url('../images/pram-icongold.png');
  background-size: 17px;
  text-decoration: underline;
}

.navlink.black {
  color: rgba(0, 0, 0, .61);
}

.navlink.black:hover {
  color: #000;
}

.navlink.dropdown {
  position: relative;
  display: block;
  padding-top: 36px;
  padding-right: 38px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
}

.navlink.dropdown.w--open {
  float: none;
  color: #db4d01;
}

.container-welcome {
  text-align: center;
}

.products {
  display: block;
  margin-right: 50px;
  margin-left: 50px;
}

.section {
  display: block;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: transparent;
}

.section.hide {
  display: block;
}

.section.grey {
  background-color: #f0f0f0;
}

.section.tint {
  background-color: #e7e7e7;
}

.slider {
  height: 700px;
  background-color: transparent;
}

.slider-nav {
  position: fixed;
  top: 719px;
  display: none;
  margin-right: 160px;
  padding-top: 16px;
  font-size: 10px;
  text-align: right;
}

.arrow {
  display: block;
  opacity: 0.8;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 36px;
}

.arrow:hover {
  opacity: 1;
}

.arrow.left {
  display: block;
  margin-left: 30px;
}

.slide {
  background-image: url('../images/2_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slide.slide-2 {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .2), transparent), url('../images/2_11.jpg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .2), transparent), url('../images/2_11.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.slide.slide-3 {
  background-image: -webkit-radial-gradient(circle closest-side at 50% 50%, rgba(0, 0, 0, .3), transparent), url('../images/1_7.jpg');
  background-image: radial-gradient(circle closest-side at 50% 50%, rgba(0, 0, 0, .3), transparent), url('../images/1_7.jpg');
  background-size: auto, cover;
}

.title-wrapper {
  margin-bottom: 70px;
  padding-right: 90px;
  padding-left: 90px;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
}

.divider {
  display: none;
  width: 7%;
  height: 2px;
  margin-right: auto;
  margin-bottom: 3%;
  margin-left: auto;
  background-color: #d6d6d6;
}

.divider.line-left {
  width: 15%;
  margin-right: 0px;
  margin-left: 0px;
}

.divider.large {
  width: 10%;
}

.sub {
  font-family: Raleway;
  color: #999;
  font-size: 18px;
  line-height: 35px;
  font-style: normal;
  font-weight: 200;
}

.elementwrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  min-height: 460px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #fff;
  -webkit-transition: color 500ms ease, box-shadow 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, box-shadow 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, box-shadow 500ms ease, transform 500ms ease;
  transition: color 500ms ease, box-shadow 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
}

.elementwrapper:hover {
  box-shadow: 0 7px 11px -1px rgba(0, 0, 0, .2);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  text-decoration: none;
}

.project-column {
  margin-bottom: 20px;
  text-align: center;
}

.overlay {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0px;
  overflow: hidden;
  width: auto;
  min-height: 118px;
  padding: 15px 20px 21px;
  background-color: rgba(0, 0, 0, .61);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
  text-decoration: none;
}

.overlay:hover {
  color: hsla(0, 0%, 100%, .61);
  text-decoration: underline;
}

.productinfo {
  position: relative;
  text-align: center;
}

.productprice {
  margin-bottom: 20px;
  padding-left: 5px;
  color: #db4d01;
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
}

.productprice._2 {
  display: block;
  margin-top: 13px;
  margin-bottom: 10px;
  color: #000;
  font-size: 21px;
  font-weight: 400;
}

.productprice._3 {
  margin-top: 12px;
  font-size: 14px;
}

.productimg {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  border: 1px none #eee;
}

.left-arrow {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  margin-right: 95px;
  margin-left: auto;
}

.left-arrow:hover {
  color: #db4d01;
}

.quote-section {
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .43), hsla(0, 0%, 100%, 0)), url('../images/sea-sunset-sunny-beach.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .43), hsla(0, 0%, 100%, 0)), url('../images/sea-sunset-sunny-beach.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.quote-section.hide {
  display: none;
}

.service-icon {
  display: none;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
  padding-top: 25px;
  border: 1px solid #c7c7c7;
  border-radius: 100%;
}

.service-icon.icon-2 {
  padding-top: 33px;
}

.service-icon.icon-3 {
  padding-top: 30px;
}

.service-column {
  margin-bottom: 30px;
  text-align: center;
}

.row-service {
  margin-top: 0px;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 7px;
  font-family: Oswald, sans-serif;
  font-size: 23px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}

.title.in-footer {
  margin-bottom: 10px;
  font-weight: 700;
}

.subtitle {
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}

.sun {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.hand-arrow {
  position: absolute;
  display: none;
  -webkit-transform: rotate(169deg);
  -ms-transform: rotate(169deg);
  transform: rotate(169deg);
}

.hand-text {
  position: absolute;
  display: none;
  margin-top: -10px;
  margin-left: -87px;
  font-family: 'Just Another Hand';
  color: #999;
  font-size: 26px;
}

.team-section {
  display: none;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f1f1f1;
}

.button-wrapper {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.button-wrapper.solid {
  margin-top: 40px;
}

.staff {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}

.staff-column {
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.staff-overlay {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, .7);
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-right: 18px;
  padding-top: 4px;
  opacity: 0.72;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.social-icon:hover {
  opacity: 1;
}

.brandsection {
  position: relative;
  padding: 140px 20px 130px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('../images/3_6.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('../images/3_6.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  color: #fff;
  font-size: 21px;
  line-height: 29px;
  font-weight: 400;
  text-align: center;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, .5);
}

.brandsection._2 {
  position: relative;
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .5), transparent), url('../images/4_5.jpg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .5), transparent), url('../images/4_5.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
  background-repeat: repeat, repeat;
  background-attachment: scroll, fixed;
}

.brandsection._3 {
  position: relative;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .43), hsla(0, 0%, 100%, 0)), url('../images/WATERSHED-X-QUIET-LIFE-11.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .43), hsla(0, 0%, 100%, 0)), url('../images/WATERSHED-X-QUIET-LIFE-11.jpg');
  background-size: auto, cover;
}

.features-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f1f1f1;
}

.features-section.hide {
  display: none;
}

.brandsrow {
  display: none;
  text-align: center;
}

.icon-features {
  width: 70px;
  height: 70px;
  margin-right: 17px;
  padding-top: 19px;
  float: left;
  border: 1px solid #c7c7c7;
  border-radius: 100%;
  text-align: center;
}

.features-text {
  overflow: hidden;
}

.list {
  margin-bottom: 50px;
}

.ul {
  margin-top: 50px;
}

.contact-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fafafa;
}

.contact-section.hide {
  display: none;
}

.begin-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 27px;
  z-index: 20;
  width: 100%;
  text-align: center;
}

.begin {
  margin-bottom: 7px;
  font-family: 'Sorts Mill Goudy';
  color: hsla(0, 0%, 100%, .8);
  font-size: 16px;
  font-style: italic;
}

.arrow-scroll {
  opacity: 0.6;
}

.inputfield {
  height: 46px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  background-color: #f7f7f7;
  -webkit-transition: border 500ms ease;
  transition: border 500ms ease;
  font-size: 14px;
  font-weight: 300;
}

.inputfield:hover {
  border-color: #2196f3;
}

.inputfield.text-area {
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 90px;
}

.inputfield.inline {
  display: inline-block;
  height: 46px;
  margin-bottom: 0px;
  padding: 8px 15px;
  border: 2px solid transparent;
  border-radius: 3px 4px 4px 3px;
  background-color: hsla(0, 0%, 100%, .8);
  -webkit-transition: opacity 500ms ease, background-color 500ms ease, color 500ms ease, border 500ms ease;
  transition: opacity 500ms ease, background-color 500ms ease, color 500ms ease, border 500ms ease;
  font-family: Lato, sans-serif;
  color: #160e09;
  font-style: normal;
  font-weight: 400;
}

.inputfield.inline:hover {
  border-color: #db4d01;
}

.inputfield.inline:focus {
  border-color: #db4d01;
  background-color: #fff;
}

.inputfield.inline.footer {
  width: 100%;
  height: 54px;
  border-radius: 3px 31px 31px 3px;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  text-align: left;
}

.inputfield.inline.qty {
  width: 50px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.column-footer {
  padding-top: 50px;
}

.map-section {
  display: none;
}

.open {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #3d3d3d;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #b8b8b8;
  font-size: 18px;
  text-align: center;
  letter-spacing: 3px;
  text-decoration: none;
}

.open:hover {
  background-color: #2b2b2b;
  color: #fff;
}

.open-text {
  display: block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.map {
  position: relative;
  z-index: 3;
  overflow: hidden;
  margin-bottom: 15px;
}

.arrow-map {
  margin-bottom: 6px;
  padding-top: 0px;
}

.bottomfooter {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #160e09;
  color: #fff;
  font-weight: 300;
}

.copyright {
  color: #fff;
  text-decoration: none;
}

.column-footer-2 {
  text-align: right;
}

.largetext {
  display: inline-block;
  margin-bottom: 10px;
  font-family: 'Lobster Two', sans-serif;
  color: #fff;
  font-size: 54px;
  line-height: 59px;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  text-transform: none;
}

.largetext._2 {
  display: block;
  padding-right: 30px;
  padding-left: 30px;
  border-bottom: 1px none #000;
  font-size: 2em;
  line-height: 1em;
}

.containerslider {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  background-color: transparent;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #fff;
  font-size: 21px;
  line-height: 29px;
  font-weight: 400;
  text-align: center;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .7);
}

.mediumtext {
  display: block;
  font-family: Lato, sans-serif;
  color: hsla(0, 0%, 100%, .61);
  font-size: 1.2em;
  line-height: 1.4em;
}

.navbar-scroll {
  position: fixed;
  z-index: 90001;
  display: none;
  width: 100%;
  padding-top: 20px;
  background-color: hsla(0, 0%, 100%, .97);
  box-shadow: 9px 9px 16px 0 rgba(0, 0, 0, .33);
  opacity: 0;
}

.email {
  color: #b5b5b5;
  text-decoration: none;
}

.banner {
  position: relative;
  background-color: #db4d01;
  background-image: none;
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
}

.banner._3 {
  position: relative;
  background-color: #2196f3;
  background-image: none;
  color: #fff;
  text-align: center;
}

.banner._4 {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .65), rgba(0, 0, 0, .65) 98%), url('../images/JAMES_SHOOTING_RAEGAN.jpg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .65), rgba(0, 0, 0, .65) 98%), url('../images/JAMES_SHOOTING_RAEGAN.jpg');
  background-size: auto, cover;
}

.banner._5 {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/WATERSHED_DRAPERS_IMAGE_2.jpg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/WATERSHED_DRAPERS_IMAGE_2.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.banner._6 {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/gallery3.jpeg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/gallery3.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.banner._7 {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/download.jpeg');
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%), url('../images/download.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  color: #c4b98e;
}

.banner.grey {
  background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%);
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, .75), rgba(0, 0, 0, .65) 98%);
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}

.banner._1 {
  background-image: none;
}

.project-detail {
  padding-top: 80px;
  padding-bottom: 80px;
}

.project-detail.hide {
  display: none;
}

.project {
  display: block;
  color: #c4b98e;
  font-weight: 300;
  text-decoration: none;
}

.project:hover {
  color: #000;
}

.listitem {
  display: block;
  margin-bottom: 30px;
  font-weight: 300;
}

.column-space-2 {
  padding-left: 20px;
}

.success-message {
  display: none;
  padding-top: 25px;
  padding-bottom: 20px;
  background-color: #d3c8b2;
  color: #fff;
  text-align: center;
}

.error-message {
  margin-top: 24px;
  padding-top: 15px;
  padding-bottom: 11px;
  text-align: center;
}

.form-paragraph {
  color: #b84949;
}

.hide {
  display: none;
}

.img {
  display: block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}

.newlabel {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 48px;
  height: 48px;
  padding-top: 13px;
  border: 2px none #fff;
  border-radius: 100%;
  background-color: #db4d01;
  opacity: 1;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.topnav {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 900;
  display: none;
  width: 100%;
  margin-left: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px none hsla(0, 0%, 100%, .1);
  background-color: #2c1c13;
  text-align: left;
}

.pullleft {
  display: inline-block;
  float: left;
}

.pullright {
  display: inline-block;
  float: right;
}

.search {
  position: relative;
  left: -50px;
  top: 0px;
  right: 0px;
  display: none;
  width: 37%;
  margin: 11px auto 0px;
  text-align: center;
}

.topfooter {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  clear: both;
  background-color: #4d301d;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  color: #fff;
  font-weight: 300;
}

.productname {
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
}

.h3 {
  padding-bottom: 20px;
  font-size: 19px;
  text-align: left;
  letter-spacing: 2px;
}

.h3.white {
  padding-left: 0px;
  border-bottom: 1px none #000;
  color: #fff;
}

.footerlink {
  display: block;
  padding: 4px 0px;
  color: hsla(0, 0%, 100%, .61);
}

.footerlink:hover {
  color: #fff;
}

.footerlink.w--current {
  color: #fff;
}

.socialicon {
  width: 52px;
  height: 52px;
  margin-right: 7px;
  padding-top: 6px;
  border: 2px solid transparent;
  border-radius: 100%;
  background-color: #db4d01;
  opacity: 1;
  -webkit-transition: background-color 500ms ease, opacity 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, opacity 500ms ease, color 500ms ease;
  font-family: 'Corin design', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 39px;
  text-align: center;
  text-decoration: none;
}

.socialicon:hover {
  border-color: #db4d01;
  background-color: transparent;
  color: #db4d01;
  text-decoration: none;
}

.footer {
  padding-top: 60px;
  padding-bottom: 60px;
  clear: both;
  background-color: #2c1c13;
  color: #fff;
  line-height: 25px;
}

.joinwrapper {
  position: relative;
  max-width: 440px;
  margin-bottom: 0px;
}

.footerelementwrapper {
  max-width: 400px;
}

.searchicon {
  display: none;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 14px;
  padding-bottom: 14px;
  float: right;
  opacity: 0.61;
}

.searchicon:hover {
  opacity: 1;
}

.fontello {
  margin-right: 3px;
  font-family: 'Corin design', sans-serif;
  font-style: normal;
}

.fontello.close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  padding: 40px;
  float: right;
  font-size: 1.7em;
  text-align: right;
}

.fontello.close:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  text-decoration: none;
}

.footertext {
  color: hsla(0, 0%, 100%, .61);
  line-height: 25px;
}

.brand {
  width: auto;
  max-width: 220px;
  margin-left: 0px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.brand:hover {
  color: #fff;
}

.brand.w--current {
  font-family: 'Questa Grande';
  color: #fff;
  font-size: 27px;
  letter-spacing: 3px;
}

.brand._2 {
  max-width: 28%;
  margin-top: 15px;
}

.weshipsection {
  height: 50px;
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #8a3557;
  text-align: center;
}

.headerh3 {
  margin-top: -3px;
  padding-bottom: 0px;
  color: #e7e7e7;
}

.creditcards {
  position: relative;
  top: 8px;
  margin-top: -17px;
  margin-right: 50px;
}

.tab.w--current {
  padding-right: 20px;
  padding-left: 20px;
  background-color: #d3c8b2;
  color: #000;
}

.tab._2 {
  display: block;
  width: 31.3%;
  margin-right: 1.5%;
  margin-bottom: 20px;
  margin-left: 1.5%;
  padding: 0px;
  float: left;
  border: 1px solid #eee;
  text-align: center;
}

.tab._2:hover {
  border: 1px solid #d3c8b2;
}

.tab._2.w--current {
  border-style: solid;
  border-color: #d3c8b2;
  background-color: transparent;
}

.tabmenu {
  margin-bottom: 40px;
  margin-left: 5px;
}

.tabmenu._2 {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  float: left;
  border-right: 1px none #000;
  border-bottom: 1px none #000;
  text-align: left;
}

.formwrapper {
  margin-bottom: 0px;
}

.dropdownlist.w--open {
  background-color: rgba(22, 14, 9, .9);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .33);
  color: #fff;
}

.dropdownlist._3 {
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  box-shadow: none;
}

.dropdownlist._3.w--open {
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}

.dropdown {
  text-transform: uppercase;
}

.m-filter-wrapper {
  display: block;
  border: 1px solid #ddd;
  background-color: #fff;
  opacity: 1;
  color: #515151;
  font-weight: 400;
}

.checkboxfield {
  margin-bottom: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 40px;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: #333;
  text-transform: none;
}

.checkboxfield:hover {
  color: #d3c8b2;
}

.white {
  padding: 17px 25px 15px;
  border-bottom: 1px solid #535353;
  font-weight: 300;
}

.megalist {
  width: 100%;
  max-width: 960px;
  min-width: 960px;
}

.megalist.w--open {
  left: -355px;
  padding: 30px 10px;
  background-color: rgba(22, 14, 9, .9);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .33);
}

.dropdownlink {
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: hsla(0, 0%, 100%, .7);
  font-weight: 400;
  text-transform: none;
}

.dropdownlink:hover {
  background-color: #db4d01;
  color: #fff;
  text-decoration: none;
}

.dropdownlink.w--current {
  color: #db4d01;
  text-decoration: none;
}

.dropdownlink.w--current:hover {
  color: #fff;
}

.heading {
  display: block;
  padding: 7px 20px 10px;
  font-family: 'Lobster Two', sans-serif;
  color: #db4d01;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
}

.heading:hover {
  color: #fff;
  text-decoration: none;
}

.dropdown-toggle {
  display: block;
  min-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  border-bottom: 1px none #ddd;
  background-color: #eee;
  font-weight: 400;
}

.blogimg {
  width: 100%;
  margin-bottom: 20px;
}

.blogpost {
  padding-right: 10px;
  padding-left: 10px;
  color: #333;
  text-decoration: none;
}

.blogpost:hover {
  color: #2196f3;
  text-decoration: none;
}

.tag {
  display: inline-block;
  width: auto;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 11px 18px;
  background-color: #2196f3;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.tag:hover {
  background-color: #001526;
  color: #2196f3;
  text-decoration: none;
}

.categories {
  margin-bottom: 40px;
}

.social {
  float: left;
}

.filter-dropdown {
  width: 100%;
  border-bottom: 1px none #ddd;
}

.filter-dropdown:hover {
  border-bottom-style: none;
}

.blogtext {
  margin-top: 17px;
  margin-bottom: 20px;
}

.col {
  padding-right: 0px;
  padding-left: 0px;
}

.icon {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.chat {
  position: fixed;
  left: 0px;
  bottom: 0%;
  z-index: 9000;
  display: none;
  padding: 10px 20px 10px 15px;
  background-color: #8a3557;
  font-family: 'Bebasneue webfont';
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.containerbanner {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 20px 50px;
}

.textarea {
  min-height: 205px;
  margin-bottom: 15px;
  border: 2px solid transparent;
  background-color: #fafafa;
  -webkit-transition: border 500ms ease;
  transition: border 500ms ease;
  font-size: 15px;
  font-weight: 300;
}

.textarea:hover {
  border-color: #2196f3;
}

.contacttext {
  margin-top: 15px;
  color: #333;
}

.categorylink {
  display: block;
  color: #818181;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
}

.categorylink:hover {
  color: #2196f3;
  text-decoration: underline;
}

.categorylink.w--current {
  color: #2196f3;
  text-decoration: none;
}

.tabcontent {
  width: 100%;
  margin-bottom: 20px;
  float: right;
  border: 1px solid #eee;
}

.featurelist {
  padding-left: 20px;
  color: #818181;
  font-weight: 300;
}

.productrow {
  margin-bottom: 90px;
}

.producttab {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.socialwrapper {
  display: block;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
}

.cartcolumn {
  width: 33%;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
}

.cartcolumn._2 {
  width: 16%;
  text-align: center;
}

.cartcolumn._3 {
  width: 8.3%;
  text-align: center;
}

.checkoutrow {
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 60px;
  border-bottom: 1px solid #dbdbdb;
  border-top-width: 1px;
  border-top-color: #000;
  font-weight: 300;
}

.paymentgateway-radio {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.paymentlabel {
  float: right;
}

.paymentlabel.creditcards {
  margin-top: -15px;
  margin-right: 0px;
}

.link {
  padding: 10px;
  color: #818181;
  text-decoration: none;
}

.link:hover {
  color: #8a3557;
}

.link.fontello.large {
  position: relative;
  top: 6px;
  font-size: 17px;
}

.checkoutcol {
  text-align: right;
}

.product-code {
  margin-top: -3px;
  margin-bottom: 20px;
  font-weight: 300;
}

.select {
  display: block;
  margin-bottom: 15px;
  color: #818181;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.select.filterselect {
  position: relative;
  top: 9px;
  display: inline-block;
  width: auto;
  margin-top: -20px;
  margin-bottom: 0px;
  float: right;
  font-style: normal;
  letter-spacing: 1.3px;
}

.select.quantity {
  display: inline-block;
  width: 58px;
  height: 48px;
  margin-top: 15px;
  margin-right: 10px;
  font-style: normal;
}

.sizewrapper {
  display: none;
}

.quantitywrapper {
  display: none;
}

.breadcrumbwrapper {
  display: inline-block;
  color: #818181;
}

.sortfilter {
  margin-bottom: 20px;
  float: left;
}

.filterselectwrapper {
  display: inline-block;
  width: auto;
  margin-top: 15px;
  color: #818181;
}

.fieldlabel {
  display: inline-block;
  margin-right: 10px;
  float: left;
  color: #818181;
  font-weight: 300;
}

.blogsocialwrapper {
  margin-top: 40px;
}

.bold {
  font-weight: 700;
}

.filtermobile {
  display: none;
}

.galleryimg {
  width: 100%;
}

.pagination {
  position: relative;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: transparent;
  text-align: left;
}

.pageno {
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: #333;
}

.results {
  position: relative;
  display: inline-block;
  padding: 13px 12px 12px 20px;
  float: right;
  border-style: solid none;
  border-width: 1px;
  border-color: #d3c8b2;
  color: #d3c8b2;
  font-weight: 300;
}

.gallerywrapper {
  margin-top: 60px;
  margin-right: -10px;
  margin-left: -10px;
}

.enquiryform {
  padding: 30px;
  background-color: #fff;
  text-align: center;
}

.modalbackground {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9000;
  display: none;
  padding-top: 18%;
  padding-bottom: 18%;
  background-color: rgba(0, 0, 0, .67);
  opacity: 0;
}

.modalwindow {
  display: block;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.contacth2 {
  margin-top: 0px;
  margin-bottom: -20px;
  font-family: Lato, sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.footer-link {
  color: #fff;
  text-decoration: underline;
}

.filter-heading {
  padding: 15px 20px;
}

.filter-heading.mobile {
  display: none;
}

.subdropdown-toggle {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 1px none #000;
}

.filter-subdropdown-list {
  position: relative;
  display: block;
  border-bottom: 1px none #ddd;
  background-color: transparent;
}

.filter-subdropdown-list:hover {
  border-bottom-style: none;
}

.filter-subdropdown-list.w--open {
  position: relative;
  display: block;
  border-bottom: 1px solid #ddd;
  background-color: transparent;
}

.filter-subdropdown-link {
  display: block;
  margin-bottom: 0px;
  padding: 7px 10px 7px 40px;
}

.filter-dropdown-list {
  position: relative;
  display: block;
  border-bottom: 1px solid #eee;
  background-color: transparent;
}

.filter-dropdown-list.w--open {
  position: relative;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.qtybasket {
  margin-bottom: 5px;
}

.space {
  margin-top: 60px;
}

.space.center {
  text-align: center;
}

.product-detail-slider {
  display: none;
}

.small-space {
  margin-top: 15px;
}

.brand-filter {
  position: absolute;
  top: -70px;
  right: 0px;
}

.brand-filter-img {
  float: right;
}

.product-detail-bottom-tabs {
  margin-top: -60px;
}

.hide-mobile {
  display: inline-block;
  margin-left: 5px;
}

.begin-wrapper {
  left: 0px;
  right: 0px;
  bottom: 30px;
  opacity: 0.8;
  -webkit-transition: color 500ms ease, opacity 500ms ease;
  transition: color 500ms ease, opacity 500ms ease;
}

.begin-wrapper:hover {
  opacity: 1;
}

.right-arrow:hover {
  color: #db4d01;
}

.top-text {
  text-align: center;
}

.gallerycol {
  padding-right: 0px;
  padding-left: 0px;
}

.gallery-title {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-bottom: 0px;
  padding: 13px;
  background-color: rgba(0, 0, 0, .8);
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}

.img-overlay {
  display: block;
  overflow: hidden;
}

.col-right-space {
  padding-right: 40px;
}

.col-left-space {
  padding-left: 40px;
}

.download-img {
  margin-top: 10px;
}

.center {
  text-align: center;
}

.row.center {
  text-align: center;
}

.area-img {
  max-width: 40px;
  margin-bottom: 20px;
  border-radius: 100%;
}

.area-col {
  display: block;
  margin: 20px auto;
  text-align: left;
}

.slider-button {
  margin-top: 20px;
  padding: 16px 25px;
  border: 2px solid transparent;
  border-radius: 30px;
  background-color: #db4d01;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
}

.slider-button:hover {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

.navlink-text {
  padding-top: 18px;
  padding-bottom: 18px;
}

.testimonial-slider-slide {
  min-height: 200px;
  padding: 50px 14%;
  background-color: #fff;
  text-align: center;
}

.testimonial-slider {
  height: auto;
}

.testimonial-left-arrow {
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: #e7e7e7;
}

.testimonial-left-arrow:hover {
  color: #db4d01;
}

.testimonial-right-arrow {
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: #e7e7e7;
}

.testimonial-right-arrow:hover {
  color: #db4d01;
}

.testimonial-slider-nav {
  font-size: 9px;
}

.banner-text-large {
  margin-bottom: 10px;
  font-family: 'Lobster Two', sans-serif;
  font-size: 66px;
  line-height: 66px;
  font-style: italic;
  font-weight: 700;
}

.col-left {
  display: inline-block;
  width: 60%;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
}

.col-right {
  display: inline-block;
  width: 40%;
  padding-right: 10px;
  padding-left: 15px;
  float: right;
}

.product-img {
  width: 50%;
  max-width: 50%;
  border: 1px solid #fff;
}

.nav-text {
  padding-top: 20px;
  padding-right: 17px;
  padding-bottom: 20px;
  border-right: 1px solid hsla(0, 0%, 100%, .2);
  color: hsla(0, 0%, 100%, .7);
}

.nav-text:hover {
  color: #db4d01;
  text-decoration: none;
}

.slider-content-block {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 50%;
  background-color: rgba(219, 77, 1, .8);
}

.social-wrapper {
  margin-top: 20px;
}

html.w-mod-js *[data-ix="hide-social-on-load"] {
  -webkit-transform: translate(0px, 60px);
  -ms-transform: translate(0px, 60px);
  transform: translate(0px, 60px);
}

html.w-mod-js *[data-ix="display-none-on-load"] {
  height: 0px;
}

html.w-mod-js *[data-ix="slide-in-slider-big-text-on-slide"] {
  opacity: 0;
}

html.w-mod-js *[data-ix="slide-in-slider-big-text-on-slide-2"] {
  opacity: 0;
  -webkit-transform: translate(0px, -40px);
  -ms-transform: translate(0px, -40px);
  transform: translate(0px, -40px);
}

html.w-mod-js *[data-ix="slide-in-slider-big-text-on-slide-3"] {
  opacity: 0;
  -webkit-transform: translate(0px, 40px);
  -ms-transform: translate(0px, 40px);
  transform: translate(0px, 40px);
}

html.w-mod-js *[data-ix="slide-out-navigation-scroll-on-load"] {
  display: none;
}

@media (max-width: 991px) {
  h2 {
    font-size: 44px;
    line-height: 44px;
  }
  .button.inline.fontello {
    top: 0px;
    right: 0px;
  }
  .button.clear {
    margin-bottom: 15px;
  }
  .navigation {
    height: auto;
  }
  .navigation-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, .75);
  }
  .navlink {
    display: none;
    background-color: transparent;
    -webkit-transition: color 500ms ease, background-color 500ms ease;
    transition: color 500ms ease, background-color 500ms ease;
    font-weight: 400;
  }
  .navlink:hover {
    background-color: #db4d01;
    color: #fff;
  }
  .navlink.w--current {
    background-color: #2196f3;
    color: #001526;
  }
  .navlink.top {
    margin-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navlink.top.w--current {
    background-color: transparent;
  }
  .navlink.dropdown {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .navlink.dropdown.w--open {
    background-color: #db4d01;
    color: #fff;
  }
  .products {
    margin-right: 10px;
    margin-left: 10px;
  }
  .slider {
    height: 510px;
  }
  .divider.line-left {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .elementwrapper {
    min-height: 560px;
  }
  .productprice {
    font-size: 14px;
  }
  .inputfield.inline {
    width: 100%;
  }
  .largetext {
    font-size: 47px;
    line-height: 47px;
  }
  .containerslider {
    padding-right: 30px;
    padding-left: 30px;
  }
  .project-detail {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .menu {
    position: relative;
    margin-top: 20px;
    padding: 13px;
    border: 2px solid transparent;
    border-radius: 3px;
    background-color: #db4d01;
    color: #fff;
  }
  .menu.w--open {
    border-color: #db4d01;
    background-color: transparent;
    color: #db4d01;
  }
  .column-space-2 {
    padding-left: 20px;
  }
  .newlabel {
    right: 10px;
  }
  .topnav {
    padding-right: 20px;
    padding-left: 20px;
  }
  .pullleft {
    margin-left: -10px;
  }
  .pullright {
    margin-right: -10px;
  }
  .search {
    left: 0px;
    display: inline-block;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 12px;
    padding-top: 0px;
  }
  .footerelementwrapper {
    margin-bottom: 20px;
  }
  .footertext {
    letter-spacing: -0.5px;
  }
  .dropdownlist.w--open {
    margin-top: 0px;
    margin-left: 0px;
    background-color: #2c1c13;
  }
  .dropdown {
    min-width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
  }
  .m-filter-wrapper {
    position: relative;
    top: 0px;
    display: block;
    width: 100%;
    max-width: 100%;
    float: none;
  }
  .white {
    text-align: left;
  }
  .megalist.w--open {
    overflow: auto;
    max-width: 100%;
    min-width: 0px;
    padding: 10px 0px;
  }
  .dropdownlink {
    padding-top: 14px;
    padding-bottom: 14px;
    color: hsla(0, 0%, 100%, .7);
  }
  .dropdownlink:hover {
    background-color: #db4d01;
    color: #fff;
  }
  .dropdownlink:active {
    color: rgba(0, 0, 0, .61);
  }
  .heading {
    text-align: left;
  }
  .producttab {
    margin-bottom: 60px;
  }
  .filtermobile {
    padding: 15px 20px;
    background-color: #818181;
    text-align: center;
  }
  .results {
    display: block;
  }
  .modalbackground {
    padding-right: 1%;
    padding-left: 1%;
  }
  .modalwindow {
    width: 90%;
  }
  .col-right-space {
    padding-right: 10px;
  }
  .col-left-space {
    padding-left: 10px;
  }
  .banner-text-large {
    font-size: 45px;
    line-height: 46px;
  }
  .banner-text-medium {
    font-size: 19px;
  }
  .col-left {
    width: 100%;
    float: right;
  }
  .col-right {
    width: 100%;
    float: left;
  }
}

@media (max-width: 767px) {
  body {
    padding-top: 0px;
  }
  h1 {
    font-size: 44px;
    line-height: 44px;
  }
  h2 {
    font-size: 40px;
    line-height: 39px;
  }
  .button.clear {
    margin-bottom: 20px;
  }
  .navigation {
    position: relative;
    top: 0px;
  }
  .navigation-menu {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .navlink {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navlink.top {
    padding-right: 9px;
    padding-left: 9px;
    letter-spacing: 1px;
  }
  .navlink.top.cart.w--current {
    background-color: transparent;
  }
  .navlink.dropdown {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .section {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .slider {
    height: 410px;
  }
  .slide.slide-2 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .title-wrapper {
    margin-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .sub {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 17px;
  }
  .row-portfolio {
    margin-bottom: 0px;
  }
  .row-portfolio.hide-mobile {
    display: none;
  }
  .project-column {
    margin-bottom: 20px;
  }
  .left-arrow {
    display: none;
  }
  .service-column {
    margin-bottom: 40px;
  }
  .row-service {
    padding-right: 10px;
    padding-left: 10px;
  }
  .button-wrapper {
    margin-top: 20px;
  }
  .staff-overlay {
    display: block;
    width: 98.5%;
    margin-right: auto;
    margin-left: auto;
  }
  .brandsection {
    padding-top: 80px;
    padding-bottom: 80px;
    font-size: 18px;
    line-height: 22px;
  }
  .bottomfooter {
    padding-right: 10px;
    padding-left: 10px;
  }
  .copyright {
    margin-bottom: 15px;
    text-align: center;
  }
  .column-footer-2 {
    text-align: center;
  }
  .largetext {
    font-size: 37px;
    line-height: 37px;
  }
  .largetext._2 {
    font-size: 2em;
  }
  .containerslider {
    margin-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
  }
  .project-detail {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .menu {
    display: inline-block;
    margin-top: 13px;
    margin-right: 15px;
  }
  .column-space {
    margin-bottom: 50px;
  }
  .column-space.space-2 {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .column-space-2 {
    margin-top: 20px;
    padding-left: 0px;
  }
  .topnav {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }
  .pullleft {
    display: inline-block;
  }
  .topfooter {
    padding: 30px 10px;
    text-align: center;
  }
  .socialicon {
    margin-right: 1%;
  }
  .footer {
    padding: 40px 10px;
    text-align: center;
  }
  .joinwrapper {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .footerelementwrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .brand {
    max-width: 190px;
    margin-left: 10px;
    padding-left: 5px;
    text-align: left;
  }
  .tab {
    width: 50%;
  }
  .tab._2 {
    width: 31%;
    margin-right: 1%;
    margin-bottom: 10px;
    margin-left: 1%;
    float: left;
  }
  .tab._2:hover {
    border-bottom-width: 1px;
  }
  .tabmenu {
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .tabmenu._2 {
    width: 100%;
  }
  .m-filter-wrapper {
    display: none;
    margin-bottom: 30px;
    opacity: 0;
  }
  .megalist.w--open {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .containerbanner {
    padding-top: 30px;
    padding-bottom: 35px;
  }
  .tabcontent {
    display: block;
    width: 100%;
    padding-right: 1%;
    padding-left: 1%;
    float: none;
  }
  .producttab {
    display: none;
  }
  .breadcrumbwrapper {
    display: block;
    margin-top: -40px;
    margin-bottom: 30px;
    float: none;
    clear: both;
  }
  .sortfilter {
    margin-bottom: 8px;
    float: none;
  }
  .filterselectwrapper {
    float: none;
  }
  .fieldlabel {
    margin-right: 5px;
  }
  .breadcrumb {
    margin-top: -20px;
    padding-right: 4px;
    text-align: left;
  }
  .blogsocialwrapper {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .filtermobile {
    display: block;
  }
  .pagination {
    margin: 10px 0% 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pageno {
    margin-right: 1.5%;
  }
  .results {
    margin-bottom: 15px;
    float: none;
    text-align: center;
  }
  .modalbackground {
    padding-right: 0px;
    padding-left: 0px;
  }
  .filter-heading {
    display: none;
  }
  .filter-heading.mobile {
    display: block;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    text-align: right;
  }
  .filter-heading.icon {
    display: inline;
    padding: 0px;
    float: right;
    border-style: none;
  }
  .qtybasket {
    text-align: left;
  }
  .product-detail-slider {
    display: block;
    height: auto;
    margin-bottom: 60px;
  }
  .product-detail-slider-arrow {
    display: none;
  }
  .product-detail-slider-nav {
    bottom: -45px;
  }
  .filter-heading-text {
    float: left;
  }
  .product-list-header-img {
    margin-bottom: 20px;
  }
  .begin-wrapper {
    bottom: 15px;
  }
  .right-arrow {
    display: none;
  }
  .col-right-space {
    margin-bottom: 20px;
  }
  .col-left-space {
    margin-top: 20px;
  }
  .area-col {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .slider-button {
    margin-top: 20px;
    padding: 13px 19px;
    font-size: 13px;
  }
  .testimonial-slider {
    padding-right: 10px;
    padding-left: 10px;
    background-color: transparent;
  }
  .testimonial-left-arrow {
    left: 10px;
    width: 50px;
  }
  .testimonial-right-arrow {
    right: 10px;
    width: 50px;
  }
  .banner-text-large {
    font-size: 39px;
    line-height: 37px;
  }
  .slider-content-block {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    background-color: transparent;
  }
}

@media (max-width: 479px) {
  .button.button-margin {
    margin-left: 0px;
  }
  .button.clear {
    position: relative;
  }
  .navlink.top {
    padding: 17px;
    font-size: 17px;
  }
  .navlink.top.cart {
    padding: 54px 21% 0px;
    float: none;
    background-position: 50% 59%;
    background-size: 21px;
  }
  .navlink.top.cart:hover {
    background-size: 21px;
  }
  .elementwrapper {
    min-height: 0px;
  }
  .staff-overlay {
    width: 100%;
  }
  .column-footer {
    margin-bottom: 40px;
  }
  .largetext._2 {
    font-size: 2em;
  }
  .containerslider {
    padding-bottom: 0px;
  }
  .mediumtext {
    font-size: 15px;
  }
  .topnav {
    text-align: center;
  }
  .pullleft {
    display: inline-block;
    width: 50%;
    margin-left: 0px;
    text-align: right;
  }
  .pullright {
    display: inline-block;
    width: 50%;
    margin-right: 0px;
    text-align: left;
  }
  .socialicon {
    margin-right: 1.5%;
    margin-bottom: 0px;
  }
  .joinwrapper {
    margin-bottom: 0px;
  }
  .brand.w--current {
    font-size: 23px;
  }
  .tab {
    border-bottom: 1px solid #fff;
    border-right-style: none;
    text-align: center;
  }
  .tab.w--current {
    border-bottom: 1px solid #dbdbdb;
    border-right-style: none;
    border-right-width: 0px;
  }
  .tabmenu {
    border-right-style: none;
    border-bottom-style: none;
  }
  .formwrapper {
    text-align: center;
  }
  .social {
    margin-right: 0px;
  }
  .social.facebook {
    margin-right: 0px;
  }
  .hidemobile {
    display: none;
  }
  .socialwrapper {
    padding-left: 0px;
  }
  .cartcolumn {
    width: 40%;
  }
  .cartcolumn._2 {
    width: 22%;
  }
  .cartcolumn._3 {
    width: 16%;
  }
  .checkoutcol {
    margin-top: 20px;
    text-align: center;
  }
  .breadcrumbwrapper {
    margin-top: 0px;
  }
  .sortfilter {
    margin-bottom: 0px;
    float: none;
  }
  .fieldlabel {
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .breadcrumb {
    padding-right: 10px;
    padding-left: 10px;
  }
  .blogsocialwrapper {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .pagination {
    margin-right: -10px;
    margin-left: -10px;
  }
  .pageno {
    width: 18%;
  }
  .modalwindow {
    width: 100%;
  }
  .hide-mobile {
    display: none;
  }
}

@font-face {
  font-family: 'Corin design';
  src: url('../fonts/corin-design.eot') format('embedded-opentype'), url('../fonts/corin-design.woff') format('woff'), url('../fonts/corin-design.ttf') format('truetype'), url('../fonts/corin-design.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}